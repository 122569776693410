import {Subject} from "rxjs";
import {SectionNames} from "@app/shared/enums";
import {NzMessageService} from "ng-zorro-antd/message";
import {sectionAchievement} from "@app/shared/constants";
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {cloneObject, selectionToTheEnd} from "@app/shared/helpers";
import {AppStateService, TemplateLogicService, TemplateService} from "@app/shared/services";
import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ClickableLinksDirective,
  ClickOutsideDirective,
  ContentEditableMaxLengthDirective,
  CtrlADirective
} from "@app/shared/directives";
import {TmDatePickerComponent} from "@app/templates/componenets/tm-date-picker/tm-date-picker.component";
import {ActionsBannerComponent, TitleActionBannerComponent, TmEditorComponent} from "@app/templates/componenets";
import {
  AllTemplatesThemeColor,
  SectionAchievement,
  SectionEl,
  SectionItemDate,
  SingleTemplateThemeColor,
  StaticSectionEl,
  Template,
  TemplateConfigs,
  TemplatePage,
  TemplateSection,
  TemplateSectionItem,
  TemplateSectionItemAch,
  TemplateSettingsThemeColor
} from "@app/shared/interfaces";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

const descSectionMaxHeight = 210;

@Component({
  selector: 'sf-work-exp-section-list',
  templateUrl: './work-exp-section-list.component.html',
  styleUrls: ['./work-exp-section-list.component.scss'],
  imports: [
    NgIf,
    NgStyle,
    TitleActionBannerComponent,
    NgClass,
    NgForOf,
    ActionsBannerComponent,
    TmDatePickerComponent,
    TmEditorComponent,
    ClickOutsideDirective,
    CtrlADirective,
    ClickableLinksDirective,
    ContentEditableMaxLengthDirective,
    TranslateModule
  ],
  standalone: true
})
export class WorkExpSectionListComponent implements OnInit, OnDestroy {
  public readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: Template<TemplateConfigs>;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;

  @Input({required: true}) public page!: TemplatePage;
  @Input({required: true}) public pageIndex!: number;

  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionIndex!: number;
  @Input({required: true}) public sectionName!: SectionNames;
  @Input({required: true}) public lastSection!: boolean;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  private focusedElText = '';
  protected selectedDate: SectionItemDate | null = null;
  protected readonly SectionNames = SectionNames;

  @Output() protected openRearrange$ = new EventEmitter<void>();
  private readonly destroy$ = new Subject<void>();

  // protected currentFontSize = this.appStateService.fontSizes.find((sizes) => sizes.name == TmFontSizesTypes.MEDIUM) as TemplateSettingsFontSize;

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onBackspaceDesc($event: Event) {
    this.templateLogicService.descDeleteSubject.next(($event.target as HTMLElement).innerText);
  }

  protected onSelectDate($event: Event, section: TemplateSectionItem) {
    $event.stopPropagation();
    this.selectedItem = section;
    this.selectedItemChange.emit(this.selectedItem);
    this.selectedDate = section.date!;
  }

  protected onClickOutsideDate() {
    this.selectedDate = null
  }

  protected getDateString(section: TemplateSectionItem) {
    const date = section.date!;

    const fromMonth = date.fromMonth ? `${date.fromMonth} ` : '';
    const fromYear = date.fromYear || '';

    const toMonth = date.toMonth ? `${date.toMonth} ` : '';
    const toYear = date.toYear || '';

    const fromString = `${fromMonth}${fromYear}`;

    let toString = toYear ? ` - ${toMonth}${toYear}` : '';

    if (toYear) {
      if (!fromMonth && !fromYear) {
        toString = `${toMonth}${toYear}`;
      }
    }

    const result = date.present ? `${fromString} - ${date.presentString}` : `${fromString}${toString}`;

    return result.trim() || date.pl;
  }

  protected handleCtrlA(item: StaticSectionEl) {
    this.appStateService.editorState$.next(item);
  }

  protected handleMouseUp(item: any) {
    setTimeout(() => {
      this.appStateService.editorState$.next(item);
    });
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;

    this.appStateService.editorState$.next(null);
  }

  /** Achievement Start */

  protected onAddAchievement($event: Event, achievements: TemplateSectionItemAch | undefined, index: number, achievementsRef: HTMLUListElement) {
    $event.preventDefault();

    if (achievements!.items.length >= 6) {
      return;
    }

    achievements!.items.splice(index + 1, 0, cloneObject(sectionAchievement));

    setTimeout(() => {
      const nextEl = achievementsRef.children[index + 1] as HTMLLIElement;
      nextEl?.focus();
      this.templateLogicService.splitToPages(this.template.settings);
      this.saveChanges();
    });
  }

  protected onRemoveAchievement($event: Event, achievements: TemplateSectionItemAch | undefined, index: number, achievementsRef: HTMLUListElement) {
    if (index === 0) {
      return;
    }

    if (achievements!.items.length > 1) {
      if (!($event.target as HTMLLIElement).innerHTML.length) {
        achievements!.items.splice(index, 1);

        setTimeout(() => {
          const prevEl = achievementsRef.children[index - 1] as HTMLLIElement;
          if (prevEl) {
            prevEl.focus();
            selectionToTheEnd(prevEl);
            this.templateLogicService.splitToPages(this.template.settings);
            this.saveChanges();
          }
        });
      }
    }
  }

  protected onDeleteAchievement($event: Event, achievements: TemplateSectionItemAch | undefined, index: number, achievementsRef: HTMLUListElement) {
    if (index === achievements!.items.length - 1) {
      return;
    }

    if (achievements!.items.length === 1) {
      return;
    }

    if (achievementsRef.children[index + 1].innerHTML.length === 0) {
      achievements!.items.splice(index + 1, 1);
    }

    this.saveChanges();
  }

  protected onAchUpArrow($event: Event, index: number, achievementsRef: HTMLUListElement) {
    setTimeout(() => {
      const prevEl = achievementsRef.children[index - 1] as HTMLLIElement;
      if (prevEl) {
        prevEl.focus();
        selectionToTheEnd(prevEl);
      }
    });
  }

  protected onAchDownArrow($event: Event, index: number, achievementsRef: HTMLUListElement) {
    setTimeout(() => {
      const nextEl = achievementsRef.children[index + 1] as HTMLLIElement;
      if (nextEl) {
        nextEl.focus();
        selectionToTheEnd(nextEl);
      }
    });
  }

  /** Achievement End */

  /** Sections Length Prevent Start */

  protected onEnterDesc($event: Event) {
    const HTMLEl = $event.target as HTMLParagraphElement;

    if (!HTMLEl.innerText.length) {
      $event.preventDefault();
    }

    if (HTMLEl.clientHeight > descSectionMaxHeight) {
      $event.preventDefault();
      this.nzMessageService.info(this.translateService.instant('project_messages.too_big_size'));
    }

    setTimeout(() => this.templateLogicService.splitToPages(this.template.settings));
    this.saveChanges();
  }

  /** Sections Length Prevent End  */

  /** On Blurs Start */

  protected onBlurSectionTitle($event: FocusEvent, section: TemplateSection) {
    section.title.text = ($event.target as HTMLHeadElement).innerHTML;
    const sameSections = this.template.settings.pages.items.map((page) => page.section.items.find((item) => item.type === section.type));
    sameSections.forEach((item) => item?.title === section.title);

    if (this.focusedElText !== section.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurSectionItemTitle($event: FocusEvent, section: TemplateSectionItem) {
    section.title!.text = ($event.target as HTMLHeadElement).innerHTML;

    if (this.focusedElText !== section.title!.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurSectionItemDesc($event: FocusEvent, sectionEL: SectionEl | undefined) {
    sectionEL!.text = ($event.target as HTMLHeadElement).innerHTML;

    if (this.focusedElText !== sectionEL!.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurAchievements($event: FocusEvent, workExp: TemplateSectionItem) {
    workExp.achievements!.title.text = ($event.target as HTMLDivElement).innerHTML;

    if (this.focusedElText !== workExp.achievements!.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurSectionItemSubTitle($event: FocusEvent, workExp: TemplateSectionItem) {
    workExp.subTitle!.text = ($event.target as HTMLHeadingElement).innerHTML;

    if (this.focusedElText !== workExp.subTitle!.text) {
      this.saveChanges();
    }
  }

  protected onBlurAchievement($event: FocusEvent, achievement: SectionAchievement) {
    achievement.text = ($event.target as HTMLLIElement).innerHTML;

    if (this.focusedElText !== achievement.text) {
      this.saveChanges();
    }
  }

  /** On Blurs End */

  protected getH2Color() {
    // const color = this.template.settings.theme.color;
    const templateId = this.template.templateId || 1;
    const color = this.appStateService.templateThemeColor['template' + templateId as keyof AllTemplatesThemeColor]?.['theme' + this.template?.settings?.settings?.theme?.colorId as keyof SingleTemplateThemeColor];

    if (this.sectionName === SectionNames.SIDEBAR_SECTION) {
      if (this.selectedItem === this.section.title) {
        return color.sidebar.sectionMainTitleFontEditModeColor;
      } else {
        return color.sidebar.sectionMainTitleFontColor;
      }
    } else {
      if (this.selectedItem === this.section.title) {
        return color.sectionMainTitleFontEditModeColor;
      } else {
        return color.sectionMainTitleFontColor;
      }
    }
  }

  public saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}


