<div class="rearrange-sections-main-container" cdkScrollable>

  <div>
    <button class="sf-btn-fifth small mb-32" (click)="onStartHowToUse()" style="border-radius: 2px;">
      <i class="ph ph-question fs-20 mr-10"></i>
      {{'builder.start_how_use' | translate}}
    </button>
  </div>

  <div class="d-flex ai-c jc-sb mb-32" id="introjs_add-section-photo">
    <div class="sf-checkbox">
      <input
        type="checkbox"
        id="tm-title"
        [(ngModel)]="template.settings.subTitle.state"
        (ngModelChange)="saveChanges()">
      <label for="tm-title">{{'builder.title' | translate}}</label>
    </div>

    <div class="sf-checkbox">
      <input
        type="checkbox"
        id="tm-summary"
        [(ngModel)]="template.settings.summary.state"
        (ngModelChange)="saveChanges()">
      <label for="tm-summary">{{'builder.summary' | translate}}</label>
    </div>

    <div class="sf-checkbox">
      <input type="checkbox" id="tm-photo" [(ngModel)]="template.settings.img.state" (ngModelChange)="saveChanges()">
      <label for="tm-photo">{{'builder.photo' | translate}}</label>
    </div>
  </div>

  <nz-divider/>

  <sf-tm-layouts [template]="template.settings"/>

  <div class="sf-info-banner info ph ph-info m-tb-32">
    <div>
      <p>{{'builder.drag_drop' | translate}}</p>
    </div>
  </div>

  <div class="all-drags-container">

    @switch (template.settings.type) {
      @case (TemplateTypes.SIDEBAR) {
        <ng-container [ngTemplateOutlet]="DragSidebarRef"/>
      }
      @case (TemplateTypes.ONE_ROW) {
        <ng-container [ngTemplateOutlet]="DragOneRowRef"/>
      }
      @case (TemplateTypes.TWO_ROWS) {
        <ng-container [ngTemplateOutlet]="DragTwoRowsRef"/>
      }
    }
  </div>

</div>

<ng-template #TmTSectionInfo>
  <div class="fs-14">
    <ul class="pl-20" style="list-style: initial">
      <li>{{'builder.to_add_new_section' | translate}}</li>
      <li>
        {{'builder.to_remove_sections' | translate}}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #DragSidebarRef>
  <div class="template-sections-preview">
    <p>{{'builder.used_sections' | translate}}</p>

    @for (page of template.settings.pages.items; let pageIndex = $index; let firstPage = $first; track page) {
      <div id="{{pageIndex === 0 ? 'intojs_tm-page' : ''}}">
        <div class="mb-20">{{'builder.page' | translate}} {{ pageIndex + 1 }}</div>

        <div class="sidebar-template mb-32" [class.sidebar-right]="template.settings.sidebarPosition === 'right'">

          <div
            class="sections-list sidebar-s"
            cdkDropList
            [cdkDropListAutoScrollStep]="10"
            id="{{'drag-sidebar-section-list-'+ pageIndex}}"
            [cdkDropListData]="page.sidebarSection.items"
            [cdkDropListConnectedTo]="getDragSidebarSectionsListIds()
                  .concat(getDragSidebarRightSectionsListIds())
                  .concat(AllDragSectionIDS.SIDEBAR)"
            (cdkDropListDropped)="onDropSection($event)">

            @if (firstPage) {
              @switch (template.templateId) {
                @case (5) {
                  <div class="rr-section-item rr-header" [ngStyle]="{height: getHeaderHeight()}">
                    <div class="empty-image">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 132 132"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="66" cy="66" r="65.5" [attr.stroke]="tmTheme.demoColor2"/>
                        <path
                          [attr.stroke]="tmTheme.demoColor2"
                          d="M86 65V71.2C86 75.6804 86 77.9206 85.1281 79.6319C84.3611 81.1372 83.1372 82.3611 81.6319 83.1281C79.9206 84 77.6804 84 73.2 84H58.8C54.3196 84 52.0794 84 50.3681 83.1281C48.8628 82.3611 47.6389 81.1372 46.8719 79.6319C46 77.9206 46 75.6804 46 71.2V60.8C46 56.3196 46 54.0794 46.8719 52.3681C47.6389 50.8628 48.8628 49.6389 50.3681 48.8719C52.0794 48 54.3196 48 58.8 48H67M80 58V46M74 52H86M74 66C74 70.4183 70.4183 74 66 74C61.5817 74 58 70.4183 58 66C58 61.5817 61.5817 58 66 58C70.4183 58 74 61.5817 74 66Z"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                }
                @case (4) {
                  <div></div>
                }
                @default {
                  @if (template.settings.img.state) {
                    <div class="rr-section-item rr-header" [ngStyle]="{height: getSidebarHeaderHeight()}">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 132 132"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="66" cy="66" r="65.5" [attr.stroke]="tmTheme.demoColor2"/>
                        <path
                          [attr.stroke]="tmTheme.demoColor2"
                          d="M86 65V71.2C86 75.6804 86 77.9206 85.1281 79.6319C84.3611 81.1372 83.1372 82.3611 81.6319 83.1281C79.9206 84 77.6804 84 73.2 84H58.8C54.3196 84 52.0794 84 50.3681 83.1281C48.8628 82.3611 47.6389 81.1372 46.8719 79.6319C46 77.9206 46 75.6804 46 71.2V60.8C46 56.3196 46 54.0794 46.8719 52.3681C47.6389 50.8628 48.8628 49.6389 50.3681 48.8719C52.0794 48 54.3196 48 58.8 48H67M80 58V46M74 52H86M74 66C74 70.4183 70.4183 74 66 74C61.5817 74 58 70.4183 58 66C58 61.5817 61.5817 58 66 58C70.4183 58 74 61.5817 74 66Z"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  }
                }
              }

              @if (template.settings.socialInfo.onSidebar) {
                <div class="rr-section-item rr-header" [ngStyle]="{height: getSidebar_SocialInfo_Height()}">
                  {{'builder.social_info' | translate}}
                </div>
              }
            }

            @for (section of page.sidebarSection.items; let sectionIndex = $index; track section.type) {
              <div
                class="rr-section-item"
                [ngStyle]="{ height: getSectionHeight(pageIndex, section) }"
                cdkDrag
                cdkDragBoundary=".all-drags-container">
                <i
                  class="ph ph-arrows-out-cardinal"
                  [nz-tooltip]="'tooltips.drag' | translate"
                  [nzTooltipMouseEnterDelay]="1"
                ></i>

                <div>{{ (section.sectionInfo?.name || '') | translate }}</div>

                <i
                  class="ph ph-x"
                  (click)="onDeleteSection(page, section, sectionIndex, SectionNames.SIDEBAR_SECTION)"
                  [nz-tooltip]="'tooltips.delete_section' | translate"
                  [nzTooltipMouseEnterDelay]="3"
                ></i>
              </div>
            } @empty {
              <ng-container [ngTemplateOutlet]="NoSectionsUsedRef"/>
            }
          </div>

          <div
            class="sections-list right-side-s"
            cdkDropList
            [cdkDropListAutoScrollStep]="10"
            id="{{'drag-sidebar-right-section-list-'+ pageIndex}}"
            [cdkDropListData]="page.sidebarRightSection.items"
            [cdkDropListConnectedTo]="getDragSidebarRightSectionsListIds()
                  .concat(getDragSidebarSectionsListIds())
                  .concat(AllDragSectionIDS.SIDEBAR)"
            (cdkDropListDropped)="onDropSection($event)">

            @if (firstPage) {
              <div
                class="rr-section-item rr-header"
                [ngStyle]="{ height: getHeaderHeight() }">
                {{'builder.header' | translate}}
              </div>
            }

            @for (section of page.sidebarRightSection.items; let sectionIndex = $index; track section.type) {
              <div
                class="rr-section-item"
                [ngStyle]="{ height: getSectionHeight(pageIndex, section) }"
                cdkDrag
                cdkDragBoundary=".all-drags-container">
                <i
                  class="ph ph-arrows-out-cardinal"
                  [nz-tooltip]="'tooltips.drag' | translate"
                  [nzTooltipMouseEnterDelay]="1"
                ></i>
                {{ (section.sectionInfo?.name || '') | translate }}
                <i
                  class="ph ph-x"
                  (click)="onDeleteSection(page, section, sectionIndex, SectionNames.SIDEBAR_RIGHT_SECTION)"
                  [nz-tooltip]="'tooltips.delete_section' | translate"
                  [nzTooltipMouseEnterDelay]="3"
                ></i>
              </div>
            } @empty {
              <ng-container [ngTemplateOutlet]="NoSectionsUsedRef"/>
            }
          </div>
        </div>
      </div>
    }
  </div>

  <!-- All Sections Sidebar -->
  <div class="template-sections-preview" id="introjs_all-sections">
    <p class="fw-bold mb-6 d-flex ai-c">
      {{'builder.all_sections' | translate}}
      <i
        class="ph ph-question ml-4 c-pointer"
        [nz-popover]="'tooltips.all_section_types' | translate"
        [nzPopoverContent]="TmTSectionInfo"
      ></i>
    </p>

    <div
      class="sections-list all-sections"
      cdkDropList
      [cdkDropListAutoScrollStep]="10"
      [id]="AllDragSectionIDS.SIDEBAR"
      [cdkDropListData]="template.settings.allSections.items"
      [cdkDropListConnectedTo]="getDragSidebarSectionsListIds().concat(getDragSidebarRightSectionsListIds())"
      cdkDropListSortingDisabled
      (cdkDropListDropped)="onDropSection($event)">

      @for (section of template.settings.allSections.items; track section.type) {
        <div
          class="rr-section-item"
          cdkDrag
          cdkDragBoundary=".all-drags-container">
          <i
            class="ph ph-arrows-out-cardinal"
            [nz-tooltip]="'tooltips.drag' | translate"
            [nzTooltipMouseEnterDelay]="1"
          ></i>
          {{ (section.sectionInfo?.name || '') | translate }}
        </div>
      } @empty {
        <ng-container [ngTemplateOutlet]="AllSectionsUsedRef"/>
      }
    </div>
  </div>
</ng-template>

<ng-template #DragOneRowRef>
  <div class="template-sections-preview">
    <p>{{'builder.used_sections' | translate}}</p>

    @for (page of template.settings.pages.items; let pageIndex = $index; let firstPage = $first; track page) {
      <div id="{{pageIndex === 0 ? 'intojs_tm-page' : ''}}">
        <div class="mb-20">{{'builder.page' | translate}} {{ pageIndex + 1 }}</div>

        <div
          class="sections-list one-row mb-32"
          cdkDropList
          [cdkDropListAutoScrollStep]="10"
          id="{{'drag-section-list-'+ pageIndex}}"
          [cdkDropListData]="page.section.items"
          [cdkDropListConnectedTo]="getDragSectionsListIds().concat(AllDragSectionIDS.ONR_ROW)"
          (cdkDropListDropped)="onDropSection($event)">

          @if (firstPage) {
            <div
              class="rr-section-item rr-header"
              [ngStyle]="{ height: getHeaderHeight() }">
              {{'builder.header' | translate}}
            </div>
          }

          @for (section of page.section.items; let sectionIndex = $index; track section.type) {
            <div
              class="rr-section-item"
              [ngStyle]="{ height: getSectionHeight(pageIndex, section) }"
              cdkDrag
              cdkDragBoundary=".all-drags-container"
              [cdkDragData]="section">
              <i
                class="ph ph-arrows-out-cardinal"
                [nz-tooltip]="'tooltips.drag' | translate"
                [nzTooltipMouseEnterDelay]="1"
              ></i>
              {{ (section.sectionInfo?.name || '') | translate }}
              <i
                class="ph ph-x"
                (click)="onDeleteSection(page, section, sectionIndex, SectionNames.SECTION)"
                [nz-tooltip]="'tooltips.delete_section' | translate"
                [nzTooltipMouseEnterDelay]="3"
              ></i>
            </div>
          } @empty {
            <ng-container [ngTemplateOutlet]="NoSectionsUsedRef"/>
          }
        </div>
      </div>
    }
  </div>

  <!-- All Sections One Row -->
  <div class="template-sections-preview" id="introjs_all-sections">
    <p class="d-flex ai-c">
      {{'builder.all_sections' | translate}}
      <i
        class="ph ph-question ml-4 c-pointer"
        [nz-popover]="'tooltips.all_section_types' | translate"
        [nzPopoverContent]="TmTSectionInfo"
      ></i>
    </p>

    <div
      class="sections-list all-sections"
      cdkDropList
      [cdkDropListAutoScrollStep]="10"
      [id]="AllDragSectionIDS.ONR_ROW"
      [cdkDropListData]="template.settings.allSections.items"
      [cdkDropListConnectedTo]="getDragSectionsListIds()"
      cdkDropListSortingDisabled
      (cdkDropListDropped)="onDropSection($event)">
      @for (section of template.settings.allSections.items; track section.type) {
        <div
          class="rr-section-item"
          cdkDrag
          cdkDragBoundary=".all-drags-container">
          <i
            class="ph ph-arrows-out-cardinal"
            [nz-tooltip]="'tooltips.drag' | translate"
            [nzTooltipMouseEnterDelay]="1"
          ></i>
          {{ (section.sectionInfo?.name || '') | translate }}
        </div>
      } @empty {
        <ng-container [ngTemplateOutlet]="AllSectionsUsedRef"/>
      }
    </div>
  </div>
</ng-template>

<ng-template #DragTwoRowsRef>
  <div class="template-sections-preview">
    <p>{{'builder.used_sections' | translate}}</p>

    @for (page of template.settings.pages.items; let pageIndex = $index; let firstPage = $first; track page) {
      <div id="{{pageIndex === 0 ? 'intojs_tm-page' : ''}}">
        <div class="mb-20">{{'builder.page' | translate}} {{ pageIndex + 1 }}</div>

        <div class="sidebar-template mb-32">
          <div
            class="sections-list two-row-l"
            cdkDropList
            [cdkDropListAutoScrollStep]="10"
            id="{{'drag-two-row-left-section-list-'+ pageIndex}}"
            [cdkDropListData]="page.twoRowLeftSideSection.items"
            [cdkDropListConnectedTo]="getDragTwoRowLeftSectionsListIds()
            .concat(getDragTwoRowRightSectionsListIds())
            .concat(AllDragSectionIDS.TWO_ROWS)"
            (cdkDropListDropped)="onDropSection($event)">

            @if (firstPage) {
              <div
                class="rr-section-item rr-header"
                [ngStyle]="{ height: getHeaderHeight() }">
                {{'builder.header' | translate}}
              </div>
            }

            @for (section of page.twoRowLeftSideSection.items; let sectionIndex = $index; track section.type) {
              <div
                class="rr-section-item"
                [ngStyle]="{ height: getSectionHeight(pageIndex, section) }"
                cdkDrag
                cdkDragBoundary=".all-drags-container">
                <i
                  class="ph ph-arrows-out-cardinal"
                  [nz-tooltip]="'tooltips.drag' | translate"
                  [nzTooltipMouseEnterDelay]="1"
                ></i>

                <div>{{ (section.sectionInfo?.name || '') | translate }}</div>

                <i
                  class="ph ph-x"
                  (click)="onDeleteSection(page, section, sectionIndex, SectionNames.TWO_ROW_LEFT_SIDE_SECTION)"
                  [nz-tooltip]="'tooltips.delete_section' | translate"
                  [nzTooltipMouseEnterDelay]="3"
                ></i>
              </div>
            } @empty {
              <ng-container [ngTemplateOutlet]="NoSectionsUsedRef"/>
            }
          </div>

          <div
            class="sections-list two-row-r"
            cdkDropList
            [cdkDropListAutoScrollStep]="10"
            id="{{'drag-two-row-right-section-list-'+ pageIndex}}"
            [cdkDropListData]="page.twoRowRightSideSection.items"
            [cdkDropListConnectedTo]="getDragTwoRowLeftSectionsListIds()
            .concat(getDragTwoRowRightSectionsListIds())
            .concat(AllDragSectionIDS.TWO_ROWS)"
            (cdkDropListDropped)="onDropSection($event)">

            @if (firstPage) {
              <div
                class="rr-section-item rr-header"
                [ngStyle]="{ height: getHeaderHeight() }">
                {{'builder.header' | translate}}
              </div>
            }

            @for (section of page.twoRowRightSideSection.items; let sectionIndex = $index; track section.type) {
              <div
                class="rr-section-item"
                [ngStyle]="{ height: getSectionHeight(pageIndex, section) }"
                cdkDrag
                cdkDragBoundary=".all-drags-container">
                <i
                  class="ph ph-arrows-out-cardinal"
                  [nz-tooltip]="'tooltips.drag' | translate"
                  [nzTooltipMouseEnterDelay]="1"
                ></i>

                <div> {{ (section.sectionInfo?.name || '') | translate }} </div>

                <i
                  class="ph ph-x"
                  (click)="onDeleteSection(page, section, sectionIndex, SectionNames.TWO_ROW_RIGHT_SIDE_SECTION)"
                  [nz-tooltip]="'tooltips.delete_section' | translate"
                  [nzTooltipMouseEnterDelay]="3"
                ></i>
              </div>
            } @empty {
              <ng-container [ngTemplateOutlet]="NoSectionsUsedRef"/>
            }
          </div>
        </div>
      </div>
    }
  </div>

  <!-- All Sections Two Rows -->
  <div class="template-sections-preview" id="introjs_all-sections">
    <p class="fw-bold mb-6 d-flex ai-c">
      {{'builder.all_sections' | translate}}
      <i
        class="ph ph-question ml-4 c-pointer"
        [nz-popover]="'tooltips.all_section_types' | translate"
        [nzPopoverContent]="TmTSectionInfo"
      ></i>
    </p>

    <div
      class="sections-list all-sections"
      cdkDropList
      [cdkDropListAutoScrollStep]="10"
      [id]="AllDragSectionIDS.TWO_ROWS"
      [cdkDropListData]="template.settings.allSections.items"
      [cdkDropListConnectedTo]="getDragTwoRowLeftSectionsListIds().concat(getDragTwoRowRightSectionsListIds())"
      cdkDropListSortingDisabled
      (cdkDropListDropped)="onDropSection($event)">
      @for (section of template.settings.allSections.items; track section.type) {
        <div
          class="rr-section-item"
          cdkDrag
          cdkDragBoundary=".all-drags-container">
          <i
            class="ph ph-arrows-out-cardinal"
            [nz-tooltip]="'tooltips.drag' | translate"
            [nzTooltipMouseEnterDelay]="1"
          ></i>
          {{ (section.sectionInfo?.name || '') | translate }}
        </div>
      } @empty {
        <ng-container [ngTemplateOutlet]="AllSectionsUsedRef"/>
      }
    </div>
  </div>
</ng-template>

<ng-template #NoSectionsUsedRef>
  <div class="no-sections-used">
    <i nz-icon nzType="inbox" nzTheme="outline"></i>
    <div class="txt-center">{{'builder.no_used_sections' | translate}}</div>
  </div>
</ng-template>

<ng-template #AllSectionsUsedRef>
  <div class="all-sections-used">
    {{'builder.all_sections_used' | translate}}
    <img src="assets/img/illustrations/all-checked.svg" alt="selfcv">
  </div>
</ng-template>
