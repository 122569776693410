import {NzIconModule} from "ng-zorro-antd/icon";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NZ_MODAL_DATA} from "ng-zorro-antd/modal";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {PageTypePipe, WorkExpSectionStPipe} from "@app/shared/pipes";
import {AppStateService, TemplateService} from "@app/shared/services";
import {CdkDrag, CdkDragHandle, CdkDropList} from "@angular/cdk/drag-drop";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgTemplateOutlet} from "@angular/common";
import {ClickOutsideDirective, ContentEditableMaxLengthDirective, CtrlADirective} from "@app/shared/directives";
import {
  AllCoverLettersThemeColor,
  CoverCompanyDetails,
  CoverLetter,
  CoverLetterSettingsThemeColor,
  SectionEl,
  SingleCoverLetterThemeColor,
  Template
} from "@app/shared/interfaces";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  ActionsBannerComponent,
  BrandingComponent,
  SkeletonComponent,
  SocialInfoSettingsComponent,
  TemplateImgComponent,
  TmEditorComponent,
  TmNamePartComponent,
  TmSummaryComponent
} from "@app/templates/componenets";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

const descSectionMaxHeight = 758;

interface DefaultItem {
  keyName: string;
  name: string;
}

interface ModalData {
  template: Template<CoverLetter>;
}

@Component({
  selector: 'sf-cover-letter',
  templateUrl: './cover-letter.component.html',
  styleUrls: ['./cover-letter.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('void', style({opacity: 0})),
      transition(':enter, :leave', [
        animate(300)
      ])
    ])
  ],
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ClickOutsideDirective,
    PageTypePipe,
    NgStyle,
    NgTemplateOutlet,
    NzIconModule,
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    NzDropDownModule,
    NzMenuModule,
    NzToolTipModule,
    ReactiveFormsModule,
    FormsModule,
    NzDividerModule,
    NzSkeletonModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    WorkExpSectionStPipe,
    SkeletonComponent,
    BrandingComponent,
    TemplateImgComponent,
    NgSwitch,
    NgSwitchCase,
    TmNamePartComponent,
    TmSummaryComponent,
    ActionsBannerComponent,
    TmEditorComponent,
    CtrlADirective,
    ContentEditableMaxLengthDirective,
    TranslateModule
  ]
})
export class CoverLetterComponent implements OnInit, OnChanges, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly elementRef = inject(ElementRef);
  private readonly nzDrawerService = inject(NzDrawerService);
  protected readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  protected selectedItem: any = null;
  protected sectionSettingsSelected = false;

  protected readonly defaults: DefaultItem[] = [
    {keyName: 'employer', name: 'Show Employer\'s Name'},
    {keyName: 'name', name: 'Show Company Name'},
    {keyName: 'address', name: 'Show Company Address'},
    {keyName: 'zip', name: 'Show City, State, ZIP Code'},
    {keyName: 'date', name: 'Show Date'},
  ];

  @Input({required: true}) public template!: Template<CoverLetter>;
  @Input() public forPrint = false;
  @Input() enableChangeDetection = true;

  private socialInfoDrawer: NzDrawerRef<SocialInfoSettingsComponent> | null = null;
  @ViewChild('DrawerTitleRef', {static: false}) private drawerTitleRef?: TemplateRef<HTMLDivElement>;

  private focusedElText = '';

  protected coverLetterTheme = {} as CoverLetterSettingsThemeColor;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: ModalData
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['template']?.currentValue) {
      this.coverLetterTheme = this.getCoverLetterTheme();
    }
  }

  ngOnInit() {
    if (this.modalData) {
      this.template = this.modalData.template;
    }

    this.handle_backward_capability();

    this.coverLetterTheme = this.getCoverLetterTheme();

    this.appStateService.templateThemeChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.coverLetterTheme = this.getCoverLetterTheme();
      });

    this.appStateService.setCoverCurrentFontSize(this.template.settings.settings.font.size.name);

  }

  ngAfterViewInit() {
    if (!this.enableChangeDetection) {
      this.changeDetectorRef.detach(); // Detach change detector to stop change detection
    } else {
      this.changeDetectorRef.reattach(); // Manually trigger change detection
    }

    this.handleForPrintCase();
  }

  private handleForPrintCase() {
    if (this.forPrint) {
      console.log('forPrint', this.forPrint);
      console.log('template', this.template);
      console.log('elementRef', this.elementRef);

      document.body.style.margin = '0';
      document.documentElement.style.margin = '0';

      document.body.style.padding = '0';
      document.documentElement.style.padding = '0';

      document.body.style.height = 'initial';
      document.body.style.minHeight = 'initial';

      document.body.style.width = 'initial';
      document.body.style.minWidth = 'initial';

      document.documentElement.style.height = 'initial';
      document.documentElement.style.minHeight = 'initial';

      document.documentElement.style.width = 'initial';
      document.documentElement.style.minWidth = 'initial';

      const templateMainContainer: HTMLDivElement = this.elementRef.nativeElement.firstChild;

      // templateMainContainer.style.pointerEvents = 'none';

      templateMainContainer.style.margin = '0';
      templateMainContainer.style.marginBottom = '-1px';
      templateMainContainer.style.overflow = 'hidden';
      templateMainContainer.style.borderRadius = '0';
      templateMainContainer.style.boxShadow = 'none';
    }
  }

  protected onOverlayClick() {
    this.selectedItem = null;
    this.sectionSettingsSelected = false;
    this.appStateService.editorState$.next(null);
    console.log('coverLetter', this.template);
  }

  protected onClickOutSide() {
    this.selectedItem = null;
    this.sectionSettingsSelected = false;
    this.appStateService.editorState$.next(null);
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  /** Social Info Start */

  protected openSocialInfoSettings(event: Event) {
    event.stopPropagation();

    this.socialInfoDrawer = this.nzDrawerService.create({
      nzTitle: this.drawerTitleRef,
      nzPlacement: 'left',
      nzContent: SocialInfoSettingsComponent,
      nzClosable: false,
      nzContentParams: {
        template: this.template.settings
      }
    });
  }

  protected onCloseSidebar() {
    this.socialInfoDrawer?.close();
  }

  /** Social Info End */

  /** Sections Length Prevent Start */

  protected onBlurText($event: FocusEvent, title: SectionEl) {
    title.text = ($event.target as HTMLElement).innerHTML;

    if (this.focusedElText !== title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onEnterDesc($event: Event) {
    const HTMLEl = $event.target as HTMLElement;

    if (!HTMLEl.innerText.length) {
      $event.preventDefault();
    }

    if (HTMLEl.clientHeight > descSectionMaxHeight) {
      $event.preventDefault();
      this.nzMessageService.info(this.translateService.instant('project_messages.too_big_size'));
    }
  }

  /** Sections Length Prevent End  */


  /** Actions Banner Start */

  protected onRemoveSectionItem(data: { state: boolean }) {
    this.selectedItem = null;
    data.state = false;
    this.saveChanges();
  }

  protected onselectSectionSettings($event: Event) {
    $event.stopPropagation();
    this.sectionSettingsSelected = !this.sectionSettingsSelected;
  }

  protected onClickOutsideWorkExpSection() {
    this.sectionSettingsSelected = false;
  }

  /** Actions Banner End */

  protected enabledSocialItemsFirst() {
    return this.template.settings.socialInfo.items.filter((item) => item.state).slice(0, 4);
  }

  protected enabledSocialItemsLast() {
    return this.template.settings.socialInfo.items.filter((item) => item.state).slice(4, 15);
  }

  protected checkboxStateChanged() {
    const names = Object.keys(this.template.settings.companyDetails)
      .filter((key) => typeof this.template.settings.companyDetails[key as keyof CoverCompanyDetails] === "object")
      .filter((key) => (this.template.settings.companyDetails[key as keyof CoverCompanyDetails] as SectionEl).state);

    if (names.length === 0) {
      this.template.settings.companyDetails.state = false;
      this.onOverlayClick();
    }

    this.saveChanges();
  }

  protected handleCtrlA(item: any) {
    this.appStateService.editorState$.next(item);
  }

  protected handleMouseUp(item: any) {
    setTimeout(() => {
      this.appStateService.editorState$.next(item);
    });
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  private getCoverLetterTheme() {
    const templateId = this.template.templateId || 1;
    return this.appStateService.coverLettersThemeColor['template' + templateId as keyof AllCoverLettersThemeColor]['theme' + this.template.settings.settings.theme.colorId as keyof SingleCoverLetterThemeColor];
  }

  /** Backward Capability */

  private handle_backward_capability() {

  }

}

