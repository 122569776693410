<div class="tm-date-picker-main">

  <div class="switch-part">
    <nz-segmented [nzOptions]="options" (nzValueChange)="handleIndexChange($event)"/>
  </div>

  <ng-container [ngTemplateOutlet]="selectedOption === 0 ? FromRef : ToRef"/>
</div>


<ng-template #FromRef>
  <div class="content-part">

    <div class="years-container">
      <div
        class="arrow-container"
        [class.disabled]="currentGroupIndex >= years.length / itemsPerGroup - 1"
        (click)="goToNextGroup()">
        <i class="ph ph-caret-left"></i>
      </div>

      <div class="years-wrapper">
        <div
          class="year-item"
          [class.active]="year === period.fromYear"
          *ngFor="let year of getCurrentGroup()"
          (click)="onSelectFromYear(year)">
          {{year}}
        </div>
      </div>

      <div class="arrow-container" [class.disabled]="currentGroupIndex === 0" (click)="goToPreviousGroup()">
        <i class="ph ph-caret-right"></i>
      </div>
    </div>

    <ng-container *ngIf="period.fromYear">
      <nz-divider class="m-tb-10"></nz-divider>

      <div class="months-container">
        <div
          class="month-item"
          [class.active]="month === period.fromMonth"
          *ngFor="let month of months"
          (click)="onSelectFromMonth(month)">
          {{month}}
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>

<ng-template #ToRef>
  <div class="content-part">

    <div class="present-container">
      <div class="sf-input2-wr" [class.disabled]="!period.present">
        <input
          type="text"
          class="sf-input2"
          placeholder="Present"
          [(ngModel)]="period.presentString"
          (ngModelChange)="onNameChanged()"
          #PresentInputElRef>
        <i class="ph ph-pencil-simple"></i>
      </div>

      <nz-switch
        [(ngModel)]="period.present"
        (ngModelChange)="onChangePresentState($event, PresentInputElRef)"
      ></nz-switch>
    </div>

    <div class="years-container" [class.disabled]="period.present">
      <div
        class="arrow-container"
        [class.disabled]="currentGroupIndex >= years.length / itemsPerGroup - 1"
        (click)="goToNextGroup()">
        <i class="ph ph-caret-left"></i>
      </div>

      <div class="years-wrapper">
        <div
          class="year-item"
          [class.active]="year === period.toYear"
          *ngFor="let year of getCurrentGroup()"
          (click)="onSelectToYear(year)">
          {{year}}
        </div>
      </div>

      <div class="arrow-container" [class.disabled]="currentGroupIndex === 0" (click)="goToPreviousGroup()">
        <i class="ph ph-caret-right"></i>
      </div>
    </div>

    <ng-container *ngIf="period.toYear">
      <nz-divider class="m-tb-10"></nz-divider>

      <div class="months-container" [class.disabled]="period.present">
        <div
          class="month-item"
          [class.active]="month === period.toMonth"
          *ngFor="let month of months"
          (click)="onSelectToMonth(month)">
          {{month}}
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>
